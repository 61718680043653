import React from 'react';
import { TinaField } from '../../../shared/types';
import { styled } from '../../../stitches.config';

export type AnchorProps = {
  id?: string | undefined;
} & TinaField;

const StyledAnchor = styled('a', {
  scrollMarginTop: '$spacingMainMenu',
  '@tabletAndUp': {
    scrollMarginTop: '$spacingMainMenuTabletAndUp',
  },
});

export const Anchor: React.FC<AnchorProps> = ({ id }) => {
  return <StyledAnchor id={id} />;
};
