import React from 'react';
import { styled } from '../../../stitches.config';
import { TinaField } from '../../../shared/types';
import { TinaMarkdown, TinaMarkdownContent } from 'tinacms/dist/rich-text';
import { Heading, LinkStyle } from '../../../shared/Typography';
import { SoftHyphen } from '../../atoms/SoftHyphen/SoftHyphen';
import { Anchor } from '../../atoms/Anchor/Anchor';

export type MarkdownWithTypoProps = {
  content: TinaMarkdownContent | TinaMarkdownContent[];
} & TinaField;

const StyledTinaMarkdown = styled('div', {
  '& a': {
    ...LinkStyle,
  },
  '& p:empty': {
    minHeight: '1rem',
  },
  '& p': {
    marginBottom: '1rem',
  },
  '& ul': {
    listStylePosition: 'outside',
    listStyleType: 'disc',
    paddingLeft: '1.3em',
    marginBottom: '1rem',
  },
  '& li ul': {
    listStyleType: 'circle',
  },
  '& li li ul': {
    listStyleType: 'square',
  },
  '& li li li ul': {
    listStyleType: 'disc',
  },
  '& li li li li ul': {
    listStyleType: 'circle',
  },
  '& li li li li li ul': {
    listStyleType: 'square',
  },
  '& ul div, & ol div': {
    display: 'inline',
  },
  '& ol': {
    counterReset: 'listCounter',
    marginBottom: '1rem',
    paddingLeft: '1.3em',
  },
  '& ol > li': {
    counterIncrement: 'listCounter',
    marginBottom: '1rem',
  },
  '& ol > li:before': {
    content: "counters(listCounter, '.') ' '",
  },
  '& > ol': {
    paddingLeft: 0,
  },
  '& > ol > li': {
    paddingLeft: 0,
  },
  '& ul ol': {
    paddingLeft: 0,
  },
  '& > ol ul': {
    paddingLeft: '2.6em',
  },
  '& > ol > li:before': {
    content: "counters(listCounter, '.') '. '",
  },
  '& > blockquote': {
    fontStyle: 'italic',
  },

  '& strong': {
    fontWeight: '$bold',
  },
  '& em': {
    fontStyle: 'italic',
  },
});

// Be sure to provide the appropriate components for each template you define

const components = {
  SoftHyphen: SoftHyphen,
  Anchor: Anchor,
  h1: (props: any) => {
    return (
      <Heading
        {...props}
        variant={{ '@initial': 'h1', '@tabletAndUp': 'h1TabletAndUp' }}
        css={{ marginBottom: '$spacing-5x' }}
      />
    );
  },
  h2: (props: any) => {
    return (
      <Heading
        {...props}
        variant={{ '@initial': 'h2', '@tabletAndUp': 'h2TabletAndUp' }}
        css={{ marginBottom: '$spacing-3x' }}
      />
    );
  },
  h3: (props: any) => {
    return (
      <Heading
        {...props}
        variant={{ '@initial': 'h3' }}
        css={{ marginBottom: '$spacing-2x' }}
      />
    );
  },
  h4: (props: any) => {
    return (
      <Heading
        {...props}
        variant={{ '@initial': 'h4', '@tabletAndUp': 'h4TabletAndUp' }}
        css={{ marginBottom: '$spacing-1x' }}
      />
    );
  },
  h5: (props: any) => {
    return (
      <Heading
        {...props}
        variant={{ '@initial': 'h5' }}
        css={{ marginBottom: '$spacing-1x' }}
      />
    );
  },
  h6: (props: any) => {
    return (
      <Heading
        {...props}
        variant={{ '@initial': 'h6' }}
        css={{ marginBottom: '$spacing-1x' }}
      />
    );
  },
};

export const MarkdownWithTypo: React.FC<MarkdownWithTypoProps> = ({
  content,
}) => {
  return (
    <StyledTinaMarkdown>
      <TinaMarkdown content={content} components={components} />
    </StyledTinaMarkdown>
  );
};
