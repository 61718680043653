import * as React from 'react';

const SvgArrowDownward = (
  props: React.SVGProps<SVGSVGElement> & { title?: string },
) => (
  <svg
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{props?.title || 'arrow downward'}</title>
    <path
      d="M13.167 12.667H3.833V3.333H8.5V2H3.833C3.093 2 2.5 2.6 2.5 3.333v9.334C2.5 13.4 3.093 14 3.833 14h9.334c.733 0 1.333-.6 1.333-1.333V8h-1.333v4.667ZM9.833 2v1.333h2.394L5.673 9.887l.94.94 6.554-6.554v2.394H14.5V2H9.833Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgArrowDownward;
