import React from 'react';
import { TinaField } from '../../../shared/types';
import { LinkPrimary } from '../../atoms/LinkPrimary/LinkPrimary';
import { TinaMarkdownContent } from 'tinacms/dist/rich-text';
import { Flex } from '../../atoms/Flex';
import { MarkdownWithTypo } from '../MarkdownWithTypo/MarkdownWithTypo';

export type ContentCenter14Props = {
  content?: TinaMarkdownContent | TinaMarkdownContent[] | null | undefined;
  href?: string | null | undefined;
  label?: string | null | undefined;
} & TinaField;

export const ContentCenter14: React.FC<ContentCenter14Props> = ({
  content,
  href,
  label,
}) => {
  return (
    <Flex
      css={{
        flexDirection: 'column',
        paddingInline: '$spacingMainMobile',
        paddingBlock: '$spacing-5x',
        background: '$skin-grey-lighten2',
        '@tabletAndUp': {
          paddingBlock: '$spacing-8x',
        },
      }}
    >
      <Flex
        css={{
          flexDirection: 'column',
          maxWidth: '$tablet',
          margin: 'auto',
          justifyContent: 'center',
          width: '100%',
          textAlign: 'center',
          gap: '$spacing-2x',
          '@tabletAndUp': {
            width: 'auto',
          },
        }}
      >
        {content && <MarkdownWithTypo content={content} />}
        {href && (
          <LinkPrimary href={href}>{label}</LinkPrimary>
          // <Link href={href}>
          //   <a>
          //     <CopyText variant="link" align="center">
          //       {label}{' '}
          //     </CopyText>
          //   </a>
          // </Link>
        )}
      </Flex>
    </Flex>
  );
};
