import * as React from 'react';

const SvgArrowForwardIos = (
  props: React.SVGProps<SVGSVGElement> & { title?: string },
) => (
  <svg
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{props?.title || 'forward'}</title>
    <path
      d="m3.558 10.115.885.885 5-5-5-5-.885.885L7.673 6l-4.115 4.115Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgArrowForwardIos;
