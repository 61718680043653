import React from 'react';
import { styled } from '../../../stitches.config';
import { TinaField } from '../../../shared/types';
import SvgArrowForwardIos from '../../atoms/svg/SvgArrowForwardIos';
import SvgArrowDownward from '../../atoms/svg/SvgArrowDownward';
import SvgNewTab from '../../atoms/svg/SvgNewTab';
import Link from 'next/link';
import { LinkStyle } from '../../../shared/Typography';

export type LinkType = 'anchor' | 'internal' | 'external';

export type LinkPrimaryProps = {
  href: string;
  type?: LinkType;
  children: React.ReactNode;
} & TinaField;

const StyledLink = styled('a', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$spacing-1x',
  margin: 'auto',

  ...LinkStyle,
});

const IconLayout = styled('div', {
  display: 'flex',
  width: '16px',
  height: '16px',
});

function getLinkTypeFromHref(href: string | undefined | null): LinkType {
  if (!href) return 'internal';
  if (href.startsWith('http')) return 'external';
  if (href.includes('#')) return 'anchor';
  return 'internal';
}

export const LinkPrimary: React.FC<LinkPrimaryProps> = ({
  href,
  type,
  children,
}) => {
  let linkType = type;

  if (!type) {
    linkType = getLinkTypeFromHref(href);
  }

  return (
    <>
      <Link href={href} passHref>
        <StyledLink
          css={{ fontWeight: '$bold' }}
          target={linkType === 'external' ? '_blank' : undefined}
        >
          {children}
          {linkType && (
            <IconLayout>
              {linkType === 'anchor' && <SvgArrowDownward />}
              {linkType === 'internal' && <SvgArrowForwardIos />}
              {linkType === 'external' && <SvgNewTab />}
            </IconLayout>
          )}
        </StyledLink>
      </Link>
    </>
  );
};
